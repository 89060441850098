<template>
  <div>
    preparing data ...
  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';

export default {
  // name: 'User Edit',
  page_title: 'Add Member : Select Type',
  created() {
    this.$store.commit('setLoaderShow', true);
    this.saveDraft(20);
  },
  methods: {
    saveDraft(type) {
      apiRequest
        .post( '/member/register', {type: type})
        .then( (res) => {
          this.$router.push('/member/add/' + res.data.last_insert_id);
        })
    }
  }
}
</script>


<style scoped>
.member-select-type {
  float: none;
  margin: auto;
  padding: 30px;
  max-width: 640px;
  background: #fff;
  text-align: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.member-select-type h2 {
  margin-bottom: 10px;
  font-size: 18px;
}
.member-select-type .col {
  padding: 10px;
}
.member-select-type a {
  display: block;
  padding: 50px 0;
  border: solid 2px #ccc;
  border-radius: 10px;
  background: #f2f2f2;
  font-size: 24px;
  line-height: 36px;
  color: #333;
}
.member-select-type a:hover {
  border-color: #39c;
  background: #ddefff;
  color: #069;
}

@media all and (max-width: 480px) {
  .member-select-type .col {
    float: none;
    width: auto;
  }
}
</style>